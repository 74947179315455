<template>
  <div class="p-8">
    <sqr-page-header title="Menu Utilisateur" />
    <div class="mt-8 bg-white shadow overflow-hidden sm:rounded-md max-w-xl">
      <ul>
        <li class="list-item">
          <router-link class="link" :to="{ name: 'profile' }">
            <div class="link-content">
              <div>Modifier mon profile</div>
              <fa :icon="['far', 'chevron-right']" />
            </div>
          </router-link>
        </li>

        <li class="list-item">
          <router-link class="link" :to="{ name: 'sign-out' }">
            <div class="link-content">
              <div>Déconnection de BRKR</div>
              <fa :icon="['far', 'chevron-right']" />
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.list-item {
  @apply border-t border-gray-200;
}
.list-item:first-child {
  @apply border-t-0;
}
.link-item {
  @apply block transition duration-150 ease-in-out;
}
.list-item:hover {
  @apply bg-gray-50;
}
.link:focus {
  @apply outline-none bg-gray-50;
}
.link-content {
  @apply flex items-center px-2 py-2 justify-between;
}
@screen sm {
  .link-content {
    @apply px-3;
  }
}
</style>

<script>
export default {
  name: 'UserMenu'
};
</script>
